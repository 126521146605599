<template>
  <ciam-page>
    <ciam-card class="select-none">
      <ciam-card-header>
        <template v-slot:header>{{ $t('deployment.new.title') }}</template>
        <template v-slot:subtitle>{{ $t('deployment.new.subtitle') }}</template>
      </ciam-card-header>
      <ciam-card-content>
        <ciam-card-content-lines>
          <!-- Deployment Name Section -->
          <ciam-card-content-line>
            <template v-slot:label>{{ $t('deployment.new.name') }}</template>
            <template v-slot:content>
              <ciam-input
                v-model="deployment.name"
                class="flex flex-col"
                data-e2e="deployment-name"
                pattern="[0-9a-z\-]{0,63}"
                required
                type="text"
              >
                <template v-slot:helpText>
                  {{
                    $t('deployment.new.nameHelpText', {
                      deployment_name: deployment.name || 'deployment_name',
                    })
                  }}
                </template>
              </ciam-input>
            </template>
          </ciam-card-content-line>
          <!-- Plan section  -->
          <ciam-card-content-line>
            <template v-slot:label>{{ $t('deployment.new.plan') }}</template>

            <template v-slot:content>
              <div class="flex justify-around flex-col md:flex-row m-0 mb-4">
                <div v-for="(planType, key) in filteredGroupedPlans" :key="key" class="w-full select-none">
                  <article
                    :class="`w-full md:w-full cursor-pointer flex justify-center rounded-md h-24 border-2 border-white md:hover:border-blue-500 ${getPlanTypeStyle(
                    planType
                  )}`"
                    :data-e2e="'plan-type-' + key"
                    @click="setPlanType(planType), setSupportLevel(planType.plans[0].id)"
                  >
                    <img :alt="planType.type" :src="planType.image" class="p-1 w-24 h-23 rounded-md" />
                  </article>
                </div>
              </div>

              <div>
                <!-- card plan  -->
                <ciam-card>
                  <ciam-card-content>
                    <ciam-card-content-lines>
                      <ciam-card-content-line :data-e2e="'selectedPlan'">
                        <template v-slot:label>{{ $t('deployment.new.selectedPlan') }}</template>
                        <template v-slot:content>
                          <ciam-text class="medium">{{ planType.type }}</ciam-text>
                        </template>
                      </ciam-card-content-line>
                      <!-- nb realms part  -->
                      <ciam-card-content-line :data-e2e="'nbRealm'">
                        <template v-slot:label>{{ $t('deployment.new.nbRealm') }}</template>
                        <template v-slot:content>
                          {{ deployment.pricingPlan.nbRealm | formatNumber() }}
                        </template>
                      </ciam-card-content-line>
                      <!-- nb user part  -->
                      <ciam-card-content-line :data-e2e="'nbUserMax'">
                        <template v-slot:label>{{ $t('deployment.new.nbUserMax') }}</template>
                        <template v-slot:content>
                          {{ deployment.pricingPlan.nbMaxUsersPerRealm | formatNumber() }}
                        </template>
                      </ciam-card-content-line>
                      <ciam-card-content-line
                        v-if="
                        deployment.pricingPlan.name.startsWith('ROARING_RABBIT_002') ||
                        deployment.pricingPlan.name.startsWith('POWER_PANDA_002') ||
                        deployment.pricingPlan.name.startsWith('ELEGANT_ERMINE_002') ||
                        deployment.pricingPlan.name.startsWith('HOT_BEAR_002') ||
                        deployment.pricingPlan.name.startsWith('WILD_WOLF_002') ||
                        deployment.pricingPlan.name.startsWith('AWESOME_APE_002')
                      "
                      >
                        <template v-slot:label>{{ $t('deployment.new.provider.label') }}</template>
                        <template v-slot:content>
                          <Promised :promise="regionsByProviders">
                            <template v-slot:pending>
                              <ciam-loader class="py-4"></ciam-loader>
                            </template>
                            <template v-slot:rejected="problem">{{ problem }}</template>
                            <template v-slot="regionsByProviders">
                              <ciam-select
                                v-model="providerAndRegion"
                                :options="regionsByProviders"
                                data-e2e="deployment-provider"
                                name="provider"
                              />
                            </template>
                          </Promised>
                          <ciam-help-text
                            v-once
                            class="medium"
                            v-text="$t('deployment.new.provider.message')"
                          ></ciam-help-text>
                        </template>
                      </ciam-card-content-line>
                    </ciam-card-content-lines>
                  </ciam-card-content>
                </ciam-card>
              </div>
            </template>
          </ciam-card-content-line>
          <ciam-card-content-line>
            <template v-slot:label>{{ $t('deployment.new.support') }}</template>
            <template v-slot:content>
              <div>
                <ciam-card>
                  <ciam-card-content>
                    <ciam-card-content-lines>
                      <!-- SLA part -->
                      <ciam-card-content-line>
                        <template v-slot:label
                        >
                          <div class="mt-3">{{ $t('deployment.new.supportLevel') }}</div>
                        </template
                        >
                        <template v-slot:content>
                          <div class="flex justify-around flex-col md:flex-row m-0 gap-2">
                            <div
                              v-if="!supportLevels.some((supportLevel) => supportLevel.label === 'standard')"
                              class="w-full select-none cursor-not-allowed"
                            >
                              <article
                                class="w-full pointer-events-none md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white text-center text-gray-500 bg-gray-100"
                                data-e2e="support-level-free"
                              >
                                Standard
                              </article>
                            </div>
                            <div v-for="(supportLevel, key) in supportLevels" :key="key" class="w-full select-none">
                              <article
                                :class="`w-full md:w-full cursor-pointer flex items-center justify-center rounded-md h-12 border-2 border-white md:hover:border-blue-500 text-center ${getSupportLevelStyle(
                                supportLevel
                              )}`"
                                :data-e2e="'support-level-' + key"
                                @click="setSupportLevel(supportLevel.value)"
                              >
                                {{ supportLevel.label | uppercaseFirstLetter() }}
                              </article>
                            </div>
                            <div
                              v-if="!supportLevels.some((supportLevel) => supportLevel.label === 'professional')"
                              class="w-full select-none cursor-not-allowed"
                            >
                              <article
                                class="w-full pointer-events-none md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white text-center text-gray-500 bg-gray-100"
                                data-e2e="support-level-professional"
                              >
                                Professional
                              </article>
                            </div>
                            <div
                              v-if="!supportLevels.some((supportLevel) => supportLevel.label === 'expert')"
                              class="w-full select-none cursor-not-allowed"
                            >
                              <article
                                class="w-full pointer-events-none md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white text-center text-gray-500 bg-gray-100"
                                data-e2e="support-level-expert"
                              >
                                Expert
                              </article>
                            </div>
                          </div>
                          <div class="flex flex-col gap-2 justify-between mt-2">
                            <div v-if="selectedSupportLevel && selectedSupportLevel.includes('STANDARD')">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Community Support
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red" />
                                Dedicated Support: Ø
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red" />
                                Support Response Time: Ø
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red" />
                                Expert Consulting On Keycloak: Ø
                              </div>
                            </div>
                            <div v-if="selectedSupportLevel && selectedSupportLevel.includes('PROFESSIONAL')">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Community Support
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Dedicated Support: e-mail, ticket center
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Support Response Time: 48h
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red" />
                                Expert Consulting on Keycloak: Ø
                              </div>
                            </div>
                            <div v-if="selectedSupportLevel && selectedSupportLevel.includes('EXPERT')">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Community Support
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Dedicated Support: e-mail, ticket center
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Support Response Time: 24h
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Expert Consulting on Keycloak: 1 day/month
                              </div>
                            </div>
                          </div>
                        </template>
                      </ciam-card-content-line>
                      <!-- Instance Type part  -->
                      <ciam-card-content-line>
                        <template v-slot:label
                        >
                          <div class="mt-2">{{ $t('deployment.new.dedicated') }}</div>
                        </template
                        >
                        <template v-slot:content>
                          <div v-if="deployment.pricingPlan.type !== 'Little Lemur'"
                               class="h-10 flex flex-row gap-2 py-2">
                            <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green"></ciam-icon>
                            <div>Dedicated instance</div>
                          </div>
                          <div v-else class="h-10 flex flex-row gap-2 py-2">
                            <div>Mutualized instance</div>
                          </div>
                        </template>
                      </ciam-card-content-line>
                      <ciam-card-content-line v-if="!isLittleLemur()" :data-e2e="'sla'">
                        <template v-slot:label
                        >
                          <div class="mt-3">{{ $t('deployment.new.sla') }}</div>
                        </template
                        >
                        <template v-slot:content>
                          <div class="flex flex-col gap-2 justify-between">
                            <div v-if="deployment.pricingPlan.sla == '99.95'">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                {{ deployment.pricingPlan.sla }} % SLA
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                <div>Incident Response Time : 1h</div>
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                <div>Comprehensive incident detection: Internal & External</div>
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                <div>Dedicated ticket center to trigger our on-call team 24/7</div>
                              </div>
                            </div>
                            <div v-if="deployment.pricingPlan.sla == '95'">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                {{ deployment.pricingPlan.sla }} % SLA
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                Incident Response Time: 12h
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl" name="fa-circle-check" style="color: green" />
                                <div>Comprehensive Incident Detection: Internal</div>
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red"></ciam-icon>
                                <div>Dedicated ticket center to trigger our on-call team : Ø</div>
                              </div>
                            </div>
                            <div v-if="deployment.pricingPlan.sla == '0'">
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red"></ciam-icon>
                                Ø SLA
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red"></ciam-icon>
                                <div>Incident Response Time: Ø</div>
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red"></ciam-icon>
                                <div>Comprehensive Incident Detection: Ø</div>
                              </div>
                              <div :class="`${getItemStyle()}`">
                                <ciam-icon class="fa-xl pl-1" name="fa-times" style="color: red"></ciam-icon>
                                <div>Dedicated ticket center to trigger our on-call team: Ø</div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </ciam-card-content-line>

                      <ciam-card-content-line>
                        <template v-slot:label
                        >
                          <div class="mt-3">{{ $t('deployment.new.price.billing') }}</div>
                        </template
                        >
                        <template v-slot:content>
                          <div class="flex justify-around flex-col md:flex-row m-0 gap-2">

                            <div v-for="(interval, key) in subscriptionInterval" :key="key" class="w-full">
                              <article
                                v-if="
                                getCurrentPlanBilling().monthly > 0 &&
                                interval.value != 'free'
                              "
                                :class="`w-full cursor-pointer md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white md:hover:border-blue-500 text-center  ${getSubscriptionIntervalStyle(
                                interval
                              )}`"
                                :data-e2e="'subscription-interval-' + key"
                                @click="setSelectedSubscriptionInterval(interval.value)"
                              >

                                <div class="flex flex-col">
                                  <div>{{ interval.value | uppercaseFirstLetter() }}</div>
                                  <div v-if="interval.value == 'yearly'">(10 % discount)</div>
                                </div>
                              </article>
                              <div
                                v-if="
                                getCurrentPlanBilling().monthly > 0 &&
                                interval.value == 'free'
                              "
                                class="cursor-not-allowed"
                              >
                                <article
                                  :class="`w-full pointer-events-none md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white text-center text-gray-500 bg-gray-100`"
                                  :data-e2e="'subscription-interval-' + key"
                                >
                                  <div class="flex flex-col gap-1">
                                    <div>{{ interval.value | uppercaseFirstLetter() }}</div>
                                  </div>
                                </article>
                              </div>
                              <div
                                v-if="
                                getCurrentPlanBilling().monthly == 0 &&
                                interval.value != 'free'
                              "
                                class="cursor-not-allowed"
                              >
                                <article
                                  :class="`w-full pointer-events-none  md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white text-center text-gray-500 bg-gray-100`"
                                  :data-e2e="'subscription-interval-' + key"
                                  @click="setSelectedSubscriptionInterval(interval.value)"
                                >
                                  <div class="flex flex-col gap-1">
                                    <div>{{ interval.value | uppercaseFirstLetter() }}</div>
                                    <div v-if="interval.value == 'yearly'">(10 % discount)</div>
                                  </div>
                                </article>
                              </div>
                              <article
                                v-if="
                                getCurrentPlanBilling().monthly == 0 &&
                                interval.value == 'free'
                              "
                                :class="`w-full cursor-pointer md:w-full flex items-center justify-center rounded-md h-12 border-2 border-white md:hover:border-blue-500 text-center ${getSubscriptionIntervalStyle(
                                interval
                              )}`"
                                :data-e2e="'subscription-interval-' + key"
                              >
                                <div class="flex flex-col gap-1">
                                  <div>{{ interval.value | uppercaseFirstLetter() }}</div>
                                </div>
                              </article>
                            </div>
                          </div>
                        </template>
                      </ciam-card-content-line>
                      <ciam-card-content-line>
                        <template v-slot:label>{{ $t('deployment.new.price.totalCost') }}</template>
                        <template v-slot:content>
                          <ciam-text

                            v-if="selectedSubscriptionInterval && selectedSubscriptionInterval == 'free'"
                            class="medium"
                          >{{ 0 | formatPriceInEuro() }}
                          </ciam-text
                          >
                          <div v-if="selectedSubscriptionInterval && selectedSubscriptionInterval == 'yearly'">
                            <ciam-text class="medium"
                            >{{ getPriceByPlan().price | formatPriceInEuro() }}
                            </ciam-text
                            >
                            <ciam-help-text class="medium"
                            >You saved {{ getPriceByPlan().savings | formatPriceInEuro() }} over one year
                              !
                            </ciam-help-text
                            >
                          </div>
                          <ciam-text
                            v-if="selectedSubscriptionInterval && selectedSubscriptionInterval == 'monthly'"
                            class="medium"
                          >{{ getPriceByPlan().price | formatPriceInEuro() }}
                          </ciam-text
                          >
                        </template>
                      </ciam-card-content-line>
                    </ciam-card-content-lines>
                  </ciam-card-content>
                </ciam-card>
              </div>
              <ciam-card v-if="isLittleLemur()" class="mt-4">
                <ciam-card-content-line>
                  <template v-slot:label
                  >
                    <div class="mt-2">{{ $t('deployment.questions.profile.question') }}</div>
                  </template
                  >
                  <template v-slot:content>
                    <ciam-select v-model="question1" :options="fillAnswers('profile')"></ciam-select>
                    <ciam-input
                      v-if="question1 === 'Other'"
                      v-model="other1"
                      class="mt-2"
                      placeholder="Other..."
                      type="text"
                    >
                    </ciam-input>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('deployment.questions.goal.question') }}</template>
                  <template v-slot:content>
                    <ciam-select v-model="question2" :options="fillAnswers('goal')"></ciam-select>
                    <ciam-input
                      v-if="question2 === 'Other'"
                      v-model="other2"
                      class="mt-2"
                      placeholder="Other..."
                      type="text"
                    >
                    </ciam-input>
                  </template>
                </ciam-card-content-line>

                <ciam-card-content-line>
                  <template v-slot:label>{{ $t('deployment.questions.knowledge.question') }}</template>
                  <template v-slot:content>
                    <ciam-select v-model="question3" :options="fillAnswers('knowledge')"></ciam-select>
                  </template>
                </ciam-card-content-line>
              </ciam-card>
            </template>
          </ciam-card-content-line>
          <!-- Admin section  -->
          <ciam-card-content v-if="isAdmin()" class="bg-purple-100">
            <ciam-card-header>
              <template v-slot:header>Admin section</template>
              <template v-slot:subtitle>Only an admin can view this section with theses custom options.</template>
            </ciam-card-header>
            <ciam-card-content-lines>
              <ciam-card-content-line>
                <template v-slot:label>{{ $t('deployment.new.advanced.imageTag') }}</template>
                <template v-slot:content>
                  <ciam-input
                    v-model="deployment.advanced.image"
                    data-e2e="deployment-image"
                    list="keycloak-version"
                    placeholder="default"
                    type="text"
                  >
                  </ciam-input>

                  <datalist id="keycloak-version">
                    <option value="keycloak-x:25.0.1"></option>
                  </datalist>
                </template>
              </ciam-card-content-line>
            </ciam-card-content-lines>
          </ciam-card-content>
          <ciam-card-content-line v-if="problem" type="full-width">
            <template v-slot:content>
              <ciam-alert :description="problem.detail" :title="problem.title" :type="AlertStatus.ALERT"></ciam-alert>
            </template>
          </ciam-card-content-line>
        </ciam-card-content-lines>
      </ciam-card-content>
      <ciam-card-footer>
        <ciam-button
          :disabled="!isFormValid || isDeploymentLoading || !areQuestionsFilled()"
          class="primary"
          data-e2e="deployment-create"
          data-trkciam-action="deployment"
          data-trkciam-category="conversion"
          data-trkciam-name="click subscription and deployment"
          @click="submit()"
          v-text="
          selectedSubscriptionInterval == 'free'
            ? $t('deployment.new.action.create')
            : $t('deployment.new.action.createAndSubscribe')
        "
        />
      </ciam-card-footer>
    </ciam-card>
  </ciam-page>
</template>

<script>
import { generatePlans } from '@/pages/PlanService';
import { routeNames } from '@/router';
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamCardFooter from '@/components/CiamCardFooter';
import CiamButton from '@/components/CiamButton';
import CiamCardContentLines from '@/components/CiamCardContentLines';
import CiamInput from '@/components/CiamInput';
import CiamSelect from '@/components/CiamSelect';
import CiamHelpText from '@/components/CiamHelpText';
import { filter, groupBy, map, pipe, values } from 'ramda';
import RegionService from '@/pages/deployments/RegionService';
import CiamText from '@/components/CiamText';
import CiamIcon from '@/components/CiamIcon';
import CheckoutService from '@/pages/deployments/CheckoutService';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import DeploymentBusiness from './DeploymentBusiness';

/**
 * @param {string} provider_id
 * @param {string} region_id
 * @return {string}
 */
function toPair(provider_id, region_id) {
  return provider_id + '::' + region_id;
}

/**
 * Curried function, take a list of regions and optionally predicate to filter the region-provider pair
 * @param regions
 */
const groupRegionsByProviders =
  (regions) =>
    (predicate = (v) => true) =>
      pipe(
        filter((region) => region.provider.available),
        groupBy((region) => region.provider.id), // => {aws: [{}, {}], gcp: [{}, {}]}
        values, // => [[{}, {}], [{}, {}]]
        map((regionsPerProvider) => {
          return {
            label: regionsPerProvider[0].provider.name,
            options: regionsPerProvider.map((region) => ({
              label: region.id + ' ' + region.name,
              value: toPair(region.provider.id, region.id),
            })),
          };
        }), // => [{label: 'provider A', options: [{}, {}]}, {label: 'provider B', options:[{}, {}]}]
        filter(predicate),
      )(regions);

export default {
  name: 'DeploymentNew',
  components: {
    CiamAlert,
    CiamText,
    CiamIcon,
    CiamButton,
    CiamCardFooter,
    CiamCardContentLine,
    CiamCardContent,
    CiamCardHeader,
    CiamCard,
    CiamInput,
    CiamCardContentLines,
    CiamHelpText,
    CiamSelect,
  },
  async mounted() {
    this.publicPlans = await generatePlans();
    this.groupedPlans = this.groupByType(this.publicPlans);
    await this.updateDataBasedOnQueryParams();
    this.updateQueryParams();
  },
  data() {
    const allRegionsByProviders = RegionService.getAll()
      .then((regions) => groupRegionsByProviders(regions)())
      .catch((problem) => this.problem = problem);
    return {
      AlertStatus: AlertStatus,
      allRegionsByProviders: allRegionsByProviders,
      selectedPlanType: this.selectedPlanType,
      selectedSubscriptionInterval: this.selectedSubscriptionInterval,
      selectedSupportLevel: this.selectedSupportLevel,
      /**
       * @type {boolean} true when a deployment HTTP request is pending
       */
      isDeploymentLoading: false,
      groupedPlans: null,
      publicPlans: {},
      deployment: {
        // the following object must follow the API interface requirements
        name: '',
        pricingPlan: {
          type: 'Little Lemur',
          image: '/img/little_lemur_256.358a3090.png',
          text: 'Little Lemur',
          nbRealm: 1,
          nbMaxUsersPerRealm: 100,
          dedicated: false,
          sla: 0,
          public: true,
          price: '0',
          supportLevel: 'standard',
          name: 'LITTLE_LEMUR_002_STANDARD',
          prices: {
            monthlyCostEur: 0,
            yearlyCostEur: 0,
            yearlyCostEurSaved: 0,
          },
        },
        advanced: {
          image: '',
        },
        provider: 'scw',
        region: 'fr-par',
      },
      planType: {
        type: 'Little Lemur',
      },
      supportLevels: [],
      publicPlanPrice: null,
      providerAndRegion: toPair('scw', 'fr-par'),
      subscriptionInterval: [
        {
          value: 'free',
          label: 'Free',
        },
        {
          value: 'yearly',
          label: '0€ / year',
          saved: 0,
        },
        {
          value: 'monthly',
          label: '0€ / month',
        },
      ],
      regionsByProviders: allRegionsByProviders,
      problem: null,
      question1: '',
      question2: '',
      question3: '',
      other1: '',
      other2: '',
    };
  },
  methods: {
    getSupportLevelFromSelectedPlanAndSupportLevel() {
      return this.selectedSupportLevel ? this.selectedSupportLevel.includes('STANDARD') ? 'standard' :
      this.selectedSupportLevel.includes('PROFESSIONAL') ? 'professional' :
      this.selectedSupportLevel.includes('EXPERT') ? 'expert' :
      'standard' :
      'standard'
    },
    getCurrentPlanBilling() {
      if (this.publicPlans[this.deployment.pricingPlan.name] === undefined) {
        return {
          monthly: 0,
          yearly: 0,
        };
      }
      if (this.publicPlans[this.deployment.pricingPlan.name].locations.length === 1) {
        return this.publicPlans[this.deployment.pricingPlan.name].locations[0].prices;
      }
      return this.publicPlans[this.deployment.pricingPlan.name].locations.filter(location => location.provider === this.providerAndRegion.split('::')[0])[0].prices;
    },
    getPriceByPlan() {
      try {
        if (this.deployment.pricingPlan.name === 'LITTLE_BUNNY_002_STANDARD') {
          const { monthly, yearly } = this.publicPlans['LITTLE_BUNNY_002_STANDARD'].locations[0].prices;
          let price = this.selectedSubscriptionInterval === 'monthly' ?
            this.publicPlans[this.deployment.pricingPlan.name].locations[0].prices.monthly.toString()
            : this.publicPlans[this.deployment.pricingPlan.name].locations[0].prices.yearly.toString();
          const savings = this.selectedSubscriptionInterval === 'yearly' ? (monthly * 12 - yearly).toString() : '000';
          price = price.toString();
          return { price: this.getPriceWithFloatFormat(price), savings: this.getPriceWithFloatFormat(savings) };
        } else {
          const prices = this.publicPlans[this.deployment.pricingPlan.name].locations.filter(location => location.provider === this.providerAndRegion.split('::')[0])[0].prices;
          const monthlyPrice = prices.monthly.toString();
          const yearlyPrice = prices.yearly.toString();
          const price = this.selectedSubscriptionInterval === 'monthly' ? `${monthlyPrice.slice(0, -2)}.${monthlyPrice.slice(-2)}` : `${yearlyPrice.slice(0, -2)}.${yearlyPrice.slice(-2)}`;
          const savings = this.selectedSubscriptionInterval === 'yearly' ? (prices.monthly * 12 - prices.yearly).toString() : '000';
          return { price, savings: this.getPriceWithFloatFormat(savings) };
        }

      } catch (e) {
        // not loaded
      }

    },
    getPriceWithFloatFormat(price) {
      return `${price.slice(0, -2)}.${price.slice(-2)}`;
    },
    fillAnswers(questionType) {
      return DeploymentBusiness.fillAnswers(questionType);
    },
    createResponseToQuestionsObject() {
      if (this.question1 === 'Other') this.question1 = this.other1;
      if (this.question2 === 'Other') this.question2 = this.other2;
      return {
        q1: this.question1,
        q2: this.question2,
        q3: this.question3,
      };
    },
    areQuestionsFilled() {
      return DeploymentBusiness.areQuestionsFilled(this.question1, this.question2, this.question3, this.planType.type, this.other1, this.other2);
    },
    // Update data based on query parameters values
    updateDataBasedOnQueryParams() {
      const queryParams = this.$route.query;

      // Update data or set default values if query parameters are empty
      const selectedPlanType = queryParams.plan_type || 'Little Lemur';
      const selectedSupportLevel = queryParams.support_level || 'LITTLE_LEMUR_002_STANDARD';
      const name = queryParams.name || '';
      const selectedSubscriptionInterval = queryParams.subscription_interval || 'free';
      const provider = queryParams.provider || 'scw';
      const region = queryParams.region || 'fr-par';

      // Check if query parameters are changed
      if (
        selectedPlanType !== this.selectedPlanType ||
        selectedSupportLevel !== this.selectedSupportLevel ||
        name !== this.deployment.name ||
        selectedSubscriptionInterval !== this.selectedSubscriptionInterval ||
        provider !== this.deployment.provider ||
        region !== this.deployment.region
      ) {
        // Update data
        this.selectedPlanType = selectedPlanType;
        this.selectedSupportLevel = selectedSupportLevel;
        this.deployment.name = name;
        this.selectedSubscriptionInterval = selectedSubscriptionInterval;
        this.deployment.provider = provider;
        this.deployment.region = region;
        this.providerAndRegion = `${provider}::${region}`;

        // Update this.planType in function of this.selectedPlanType
        const selectedPlanTypeData = this.groupedPlans.find((group) => group.type === this.selectedPlanType);
        this.planType = { ...selectedPlanTypeData };

        // Update this.supportLevels in function of this.selectedPlanType
        this.supportLevels = [];
        selectedPlanTypeData.plans.forEach((plan) => {
          this.supportLevels.push({
            value: plan.id,
            label: plan.supportLevel,
          });
        });

        // Update this.deployment.pricingPlan in function of this.selectedSupportLevel
        this.deployment.pricingPlan = this.publicPlans[this.selectedSupportLevel];

        // Update this.regionsByProviders in function of this.deployment.provider et this.deployment.region
        this.setRegionsByProviders();

        this.updateQueryParams();
      }
    },

    updateQueryParams: async function() {
      let loader = this.$loading.show();
      try {
        const queryParams = this.$route.query;

        // Update query parameters
        this.$router
          .replace({
            query: {
              ...queryParams,
              plan_type: this.selectedPlanType,
              support_level: this.selectedSupportLevel,
              name: this.deployment.name,
              subscription_interval: this.selectedSubscriptionInterval,
              provider: this.deployment.provider,
              region: this.deployment.region,
            },
          })
          .catch((error) => {
            if (
              error.name !== 'NavigationDuplicated' &&
              !error.message.includes('Avoided redundant navigation to current location')
            ) {
              console.log(error);
            }
          });
      } catch (error) {
        this.$log.error("Error while using query params", error);
        this.totalElements = 0;
      } finally {
        loader.hide();
      }
    },

    handleSelectedSupportLevelChange() {
      // Update the plan type
      this.setPlanType(this.planType);
    },
    isLittleLemur() {
      return DeploymentBusiness.isLittleLemur(this.planType.type);
    },
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
    getPlanTypeStyle(key) {
      return DeploymentBusiness.getPlanTypeStyle(this.planType.type, key);
    },
    getSupportLevelStyle(key) {
      return DeploymentBusiness.getSupportLevelStyle(this.selectedSupportLevel, key);
    },
    getSubscriptionIntervalStyle(key) {
      return DeploymentBusiness.getSubscriptionIntervalStyle(this.selectedSubscriptionInterval, key);
    },
    getItemStyle() {
      return DeploymentBusiness.getItemStyle();
    },
    // Group plans by type (ex: group all roaring rabbit plans (ex: standard && professionnal))
    groupByType(plans) {
      return DeploymentBusiness.groupByType(plans);
    },

    async setDeploymentPricingPlan(plan) {
      this.deployment.pricingPlan = plan;
      this.setRegionsByProviders();
    },

    setSupportLevel(supportLevelId) {
      // Update the associated query parameter
      this.selectedSupportLevel = supportLevelId;
      this.updateQueryParams();
    },

    setSelectedSubscriptionInterval(subscriptionIntervalValue) {
      this.selectedSubscriptionInterval = subscriptionIntervalValue;
      this.updateQueryParams();
    },

    setRegionsByProviders() {
      this.regionsByProviders = this.allRegionsByProviders;
      this.updateQueryParams();
    },

    setPlanType(planType) {
      // Get the chosen current plan type (ex: roaring rabbit)
      this.planType = { ...planType };

      this.selectedPlanType = planType.type;

      // Depending on the chosen plan type, we add in an array all the levels of support that this type of plan offers (ex: 'standard', 'expert', ...)
      this.supportLevels = [];

      planType.plans.forEach((plan) => {
        this.supportLevels.push({
          value: plan.id,
          label: plan.supportLevel,
        });
      });

      // Next, we find the public plan price based on the current selected support level (ex: 'expert')
      const publicPlanPrice = this.publicPlans[this.selectedSupportLevel];


      // To finish, we set the deployment pricing plan to update the pricing
      this.setDeploymentPricingPlan(publicPlanPrice);
      this.getPriceByPlan();
      if (publicPlanPrice.type !== 'Little Lemur') {
        // According to the public plan price, we set the subscription interval
        // If the price is more than 0, we consider it is not a free plan
        if (this.getCurrentPlanBilling().monthly > 0) {
          this.setSelectedSubscriptionInterval('monthly');
        } else {
          this.setSelectedSubscriptionInterval('free');
        }
      } else {
        this.setSelectedSubscriptionInterval('free');
      }
    },
    async submit() {
      const baseUrl = window.location.protocol + '//' + window.location.host;

      const successUrl = this.$router
        .getRoutes()
        .filter((candidate) => candidate.name === 'DeploymentCreationSuccess')
        .map((route) => route.path);
      const cancelUrl = this.$router
        .getRoutes()
        .filter((candidate) => candidate.name === 'DeploymentList')
        .map((route) => route.path);
      const marketing = this.createResponseToQuestionsObject();
      const deploymentPayload = DeploymentBusiness.createDeploymentPayload(this.deployment, marketing, this.$route.query.organization_id, this.selectedSubscriptionInterval, baseUrl, successUrl, cancelUrl);

      let loader = this.showLoader();
      let response;
      try {
        response = await CheckoutService.checkout(deploymentPayload);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.problem = err;
        return;
      } finally {
        this.isDeploymentLoading = false;
        loader.hide();
      }

      if (response.customerActionUrl) {
        // redirect to the checkout billing url
        // hope to see you soon dear customer 🤞
        window.location = response.customerActionUrl;
      } else {
        this.$router.push({ name: routeNames.DeploymentCreationSuccess, query: { 
          organization_id: this.$route.query.organization_id, 
          name: this.deployment.name, 
          planType: this.planType.type,  
          supportLevel: this.getSupportLevelFromSelectedPlanAndSupportLevel(), 
          provider: this.providerAndRegion.split("::")[0], 
          region: this.providerAndRegion.split("::")[1]
        }});
      }
    },

    showLoader() {
      this.isDeploymentLoading = true;
      let _loader = this.$loading.show();
      return {
        hide() {
          _loader.hide();
        },
      };
    },

    /**
     * @param {string} providerAndRegion
     * @return {{provider_id: string, region_id: string}}
     */
    fromPair(providerAndRegion) {
      return DeploymentBusiness.fromPair(providerAndRegion);
    },
  },
  watch: {
    '$route.query': {
      handler: 'updateQueryParams',
      deep: true,
    },
    providerAndRegion: function(value) {
      const { provider_id, region_id } = this.fromPair(value);
      this.deployment.provider = provider_id;
      this.deployment.region = region_id;
      this.updateQueryParams();
    },

    selectedSupportLevel(newSupportLevel) {
      this.handleSelectedSupportLevelChange(newSupportLevel);
    },
  },

  computed: {
    filteredGroupedPlans() {
      if (!this.groupedPlans) return [];
      return this.groupedPlans.filter((planType) => {
        return planType.plans.some((plan) => plan.displayPrice === true);
      });
    },
    isFormValid() {
      return this.deployment.name.trim() !== '' && this.deployment.pricingPlan;
    },
  },
};
</script>
