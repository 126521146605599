import { problemAwareHttp } from '@/http';

export default class CheckoutService {
  /**
   * Create a deployment
   * @param {Object} checkout the overall checkout object
   * @param {String} checkout.name the deployment name
   * @param {Object} checkout.subscriber the subscriber section
   * @param {String} checkout.subscriber.organizationId the organization that request the checkout
   * @param {Object} checkout.plan the selected plan
   * @param {String} checkout.plan.plan the plan name
   * @param {String} checkout.plan.provider the cloud provider
   * @param {String} checkout.plan.region the cloud region
   * @param {Object} checkout.advanced - advanced configuration
   * @param {String} checkout.advanced.image - image suffix
   * @param {Object} checkout.workflow - the workflow
   * @param {String} checkout.workflow.successUrl - where to redirect the user after a successful checkout
   * @param {String} checkout.workflow.cancelUrl - where to redirect the user after a cancelled / error checkout
   * @param {Object} checkout.marketing - All the questions answered before the Little Lemur deployment
   * @return {Promise<{String}>}
   */
  static async checkout({ name, subscriber, plan, advanced, workflow, marketing }) {
    plan.paymentProvider = 'stripe';
    return problemAwareHttp
      .post(`/checkout/`, { name, subscriber, plan, advanced, workflow, marketing })
      .then((response) => {
        return response.data;
      });
  }
}
