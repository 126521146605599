import i18n from '@/i18n.js';

export default class DeploymentBusiness {

  static getPlanIdFromLegacy(planId) {
    if (planId.startsWith('CUSTOM_') || planId.startsWith('MUTUALIZED') || planId === 'FREEMIUM_INSTANCE') {
      return planId;
    } else {
      switch (planId) {
        case 'LITTLE_LEMUR':
          return 'LITTLE_LEMUR_002_STANDARD';
        case 'LITTLE_BUNNY_001':
          return 'LITTLE_BUNNY_002_STANDARD';
        case 'LITTLE_BUNNY':
          return 'LITTLE_BUNNY_002_STANDARD';
        case 'BIG_BUNNY':
          return 'LITTLE_BUNNY_002_STANDARD';
        case 'BIG_BUNNY_001':
          return 'LITTLE_BUNNY_002_STANDARD';
        case 'ROARING_RABBIT_001':
          return 'ROARING_RABBIT_002_STANDARD';
        case 'ROARING_RABBIT':
          return 'ROARING_RABBIT_002_STANDARD';
        case 'POWER_PANDA_001':
          return 'POWER_PANDA_002_STANDARD';
        case 'POWER_PANDA':
          return 'POWER_PANDA_002_STANDARD';
        default:
          return planId;
      }
    }
  }

  static getImageFromPlanId(planId) {
    if (planId.includes('LEMUR')) {
      return '/img/little_lemur_256.358a3090.png';
    } else if (planId.includes('BUNNY')) {
      return '/img/little_bunny_256.acf06fd5.png';
    } else if (planId.includes('RABBIT')) {
      return '/img/roaring_rabbit_256.e1446d19.png';
    } else if (planId.includes('PANDA')) {
      return '/img/power_panda_256.04d1b914.png';
    } else if (planId.includes('ERMINE')) {
      return '/img/elegant_ermine_256.7b25670f.png';
    } else if (planId.includes('WOLF')) {
      return '/img/wild_wolf_256.a5df49e5.png';
    } else if (planId.includes('BEAR')) {
      return '/img/hot_bear_256.f67438d9.png';
    } else if (planId.includes('APE')) {
      return '/img/awesome_ape_256.154c431b.png';
    } else if (planId.includes('FREEMIUM')) {
      return '/img/little_lemur_256.358a3090.png';
    } else if (planId.startsWith('CUSTOM_') || planId.startsWith('MUTUALIZED')) {
      return '/img/custom_plan_256.ddd80f20.png';
    }
  }

  static getPercentRealms(statistics) {
    if (!statistics) return 0;
    const rate = statistics.realmsCount / (statistics.maxRealmsCount || 1);
    return Math.round(rate * 100);
  }

  static getPercentUsers(statistics) {
    if (!statistics) return 0;
    const rate = statistics.usersCount / (statistics.maxUsersCountPerRealm || 1);
    return Math.round(rate * 100);
  }

  static getUsersProgressStyle(statistics) {
    if (!statistics) return 'is-default';
    if (statistics.usersCount > (statistics.maxUsersCountPerRealm || 1)) {
      return 'is-danger';
    }
    return 'is-success';
  }

  static getRealmsProgressStyle(statistics) {
    if (!statistics) return 'is-default';
    if (statistics.realmsCount > (statistics.maxRealmsCount || 1)) {
      return 'is-danger';
    }
    return 'is-success';
  }

  static fillAnswers(questionType) {
    let questionAnswers = i18n.t(`deployment.questions.${questionType}.responses`);
    let selectQuestion = [{ label: 'Select an Answer', value: '', disabled: true }];
    Object.keys(questionAnswers).forEach((key) => {
      selectQuestion.push({
        label: i18n.t(`deployment.questions.${questionType}.responses.${key}`),
        value: i18n.t(`deployment.questions.${questionType}.responses.${key}`),
      });
    });
    questionType !== 'knowledge'
      ? selectQuestion.push({
        label: i18n.t('deployment.questions.other'),
        value: i18n.t('deployment.questions.other'),
      })
      : null;
    return selectQuestion;
  }

  static areQuestionsFilled(question1, question2, question3, planType, other1, other2) {
    return (
      (((question1 !== '') & (question1 !== 'Other') ||
          (question1 === 'Other' && other1 !== '')) &
        ((question2 !== '') & (question2 !== 'Other') ||
          (question2 === 'Other' && other2 !== '')) &
        (question3 !== '')) === 1 ||
      planType !== 'Little Lemur'
    );
  }

  static isLittleLemur(planType) {
    return planType === 'Little Lemur';
  }

  static getPlanTypeStyle(planType, key) {
    const isPlanTypeSelected = planType === key.type;
    return isPlanTypeSelected ? 'bg-indigo-600' : 'bg-gray-100';
  }

  static getSupportLevelStyle(supportLevel, key) {
    const isSupportLevelSelected = supportLevel === key.value;
    return isSupportLevelSelected ? 'bg-indigo-600 text-white font-medium' : 'bg-white border-2 border-blue-200 ';
  }

  static getSubscriptionIntervalStyle(subscriptionInterval, key) {
    const isSubscriptionIntervalSelected = subscriptionInterval === key.value;
    return isSubscriptionIntervalSelected
      ? 'bg-indigo-600 text-white font-medium'
      : 'bg-white border-2 border-blue-200';
  }

  static getItemStyle() {
    return 'h-10 flex flex-row gap-2 py-2';
  }
  static groupByType(plans) {
    const groupedPlans = [];

    for (const key in plans) {
      const plan = plans[key];
      const existingGroup = groupedPlans.find((group) => group.type === plan.type);

      if (existingGroup) {
        existingGroup.plans.push({
          id: key,
          supportLevel: plan.supportLevel,
          image: plan.image,
          text: plan.text,
          nbRealm: plan.nbRealm,
          nbMaxUsersPerRealm: plan.nbMaxUsersPerRealm,
          dedicated: plan.dedicated,
          public: plan.public,
          price: plan.price,
          displayPrice: true,
        });
      } else {
        groupedPlans.push({
          type: plan.type,
          image: plan.image,
          plans: [
            {
              id: key,
              supportLevel: plan.supportLevel,
              image: plan.image,
              text: plan.text,
              nbRealm: plan.nbRealm,
              nbMaxUsersPerRealm: plan.nbMaxUsersPerRealm,
              dedicated: plan.dedicated,
              public: plan.public,
              price: plan.price,
              displayPrice: true,
            },
          ],
        });
      }
    }
    return groupedPlans;
  }

  static getPrices(price) {
    const monthlyCostEur = price.monthlyCostEur / 100;
    const yearlyCostEur = price.yearlyCostEur / 100;
    const monthlyCostEurForOneYear = monthlyCostEur * 12;
    const yearlyCostEurSaved = monthlyCostEurForOneYear - yearlyCostEur;
    return {
      monthlyCostEur: monthlyCostEur,
      monthlySubscriptionUrl: price.monthlySubscriptionUrl,
      yearlyCostEur: yearlyCostEur,
      yearlySubscriptionUrl: price.yearlySubscriptionUrl,
      yearlyCostEurSaved: yearlyCostEurSaved,
    };
  }

  static fromPair(providerAndRegion) {
    const [provider_id, region_id] = providerAndRegion.split('::');
    return { provider_id, region_id };
  }

  static getSubscriptionInterval(deployment) {
    return [
      {
        value: 'free',
        label: i18n.t('deployment.new.price.free', {
          price: 0,
        }),
      },
      {
        value: 'yearly',
        label: i18n.t('deployment.new.price.yearly', {
          price: deployment.pricingPlan.prices.yearlyCostEur,
        }),
        saved: deployment.pricingPlan.prices.yearlyCostEurSaved,
      },
      {
        value: 'monthly',
        label: i18n.t('deployment.new.price.monthly', {
          price: deployment.pricingPlan.prices.monthlyCostEur,
        }),
      },
    ];
  }

  static createDeploymentPayload(deployment, marketing, organizationId, selectedSubscriptionInterval, baseUrl, successUrl, cancelUrl) {
    return {
      marketing,
      name: deployment.name,
      subscriber: {
        organizationId,
      },
      plan: {
        plan: deployment.pricingPlan.name,
        supportLevel: deployment.pricingPlan.supportLevel || 'standard',
        provider: deployment.provider,
        billingCycle: selectedSubscriptionInterval || 'yearly',
        region: deployment.region,
      },
      advanced: {
        image: deployment.advanced.image,
      },
      workflow: {
        successUrl: `${baseUrl}/${successUrl}`,
        cancelUrl: `${baseUrl}/${cancelUrl}`,
      },
    };
  }
}
